import React, { useState } from "react";
import logo from "./playdo-logo.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import "./styles.css"; // Import the CSS file where the animation is defined

const LandingPage = () => {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false); // State to handle form submission

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await fetch(
        "https://nameless-shore-65210-cc473cb7b162.herokuapp.com/api/waitlist",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      if (response.ok) {
        setSubmitted(true);
      } else {
        console.error("Failed to submit email");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-moving-gradient text-gray-400">
      {/* Navbar with vertical spacing */}
      <nav className="w-full flex justify-between items-center bg-opacity-80 bg-white py-6 px-8 rounded-lg shadow-md">
        <a href="./">
          <img
            src={logo}
            alt="Playdo Logo"
            className="w-36 h-auto"
          />
        </a>
      </nav>

      {/* Main Content */}
      <main className="flex-grow flex flex-col items-center">
        {/* Main Message */}
        <div className="text-white text-center text-6xl font-extrabold mt-24 mb-4">
          AI-Powered Technical Interview Prep
        </div>

        {/* New Description Text with Enhanced Styling */}
        <div className="text-white text-center text-2xl max-w-5xl mb-16 px-6 leading-relaxed">
          <p>
            <strong>Playdo.ai</strong> is an AI-powered interview prep platform designed to help competitive coders, 
            computer science students, and professionals excel in technical interviews.
          </p>
          <p className="mt-2">
            Playdo generates realistic interviewer avatars and unique coding questions to simulate real interview environments, enhancing both 
            problem-solving and communication skills.
          </p>
          <p className="mt-2">
            Get ready to elevate your technical interview game with immersive, AI-driven practice.
          </p>
        </div>

        {/* Confirmation Message or Waitlist Form */}
        <div className="w-full flex flex-col items-center text-white mb-16">
          {!submitted ? (
            <>
              <p className="text-3xl font-semibold mb-4">Get Early Access Now</p>
              <form
                onSubmit={handleSubmit}
                className="flex flex-row items-center space-x-2 w-full max-w-lg"
              >
                <input
                  className="flex-grow p-4 rounded-full text-gray-800 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200 ease-in-out"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  required
                />
                <button
                  className="bg-blue-700 text-white py-3 px-6 rounded-full font-semibold transition duration-300 ease-in-out hover:bg-blue-600"
                  type="submit"
                >
                  Join Waitlist
                </button>
              </form>
            </>
          ) : (
            <div className="flex flex-col items-center mt-8">
              <p className="text-2xl">
                Thank you for joining the Playdo.ai waitlist! Please check your email (and your spam) to stay updated.
              </p>
            </div>
          )}
        </div>
      </main>

      {/* Footer */}
      <footer className="bg-white bg-opacity-60 rounded-lg text-gray-700 py-4 px-8 flex justify-between items-center">
        <div className="flex space-x-4">
          <a href="mailto:playdoaibeta@gmail.com" className="text-blue-600 hover:text-blue-700">
            <FontAwesomeIcon icon={faEnvelope} className="w-6 h-6" />
          </a>
          <a href="https://www.linkedin.com/company/playdoai" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-700">
            <FontAwesomeIcon icon={faLinkedin} className="w-6 h-6" />
          </a>
        </div>
        <div className="text-blue-600">
          &copy; 2024 Playdo.ai Inc. All rights reserved.
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;